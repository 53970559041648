import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useFrame, useThree} from '@react-three/fiber'
import { Html } from '@react-three/drei';

import {
  useHistory,
  useRouteMatch
} from "react-router-dom";

export default function Thing({link, navigate, action, position, children, layers, text}) {

    const mesh = useRef()
    const html = useRef()
    const [hovered, setHover] = useState(false)
    const [active, setActive] = useState(false)
    
    const camera = useThree((state) => state.camera);
  
    useFrame(() => {
      if (mesh.current == null) {
        return;
      }
      var i;
      if (hovered && !active) {
        i = 0.05;
      } else {
        i = 0.01;
      }
      mesh.current.rotation.x = mesh.current.rotation.y += i;
    })
  
    const handleOnClick = useCallback(() => {
      if (link !== undefined) {
          navigate(link)
      }
      if (action !== undefined && action instanceof Function)  {
          action();
      }
    });

    useEffect(() => {
      if (position !== undefined) {
        mesh.current.position.set(position[0] * camera.aspect, position[1], position[2]);
      }
    }, [position])
 
    // function a() {
    //   console.log("VISIBLE " + visible.min.x);
    //   let size = new THREE.Box3().setFromObject(mesh.current).getSize(new THREE.Vector3());
    //   const dimensions = new THREE.Vector3().subVectors( box3.max, box3.min );
      // console.log(">> X " + d + " -- " + visibleBox(70).min.x + " " + visibleBox(70).max.x)
      // console.log(">> Y " + d + " -- " + visibleBox(70).min.y + " " + visibleBox(70).max.y)
  
  
      // scene.add(makeBox(mesh.current))
  
      // const geometry = new THREE.CircleGeometry( 5, 32 );
      // const material = new THREE.MeshBasicMaterial( { color: 0xff0000 } );
      // const circle = new THREE.Mesh( geometry, material );
      // circle.position.set(visibleBox(70).min);
      // circle.position.set(visibleBox(70).min.x, visibleBox(70).min.y, 0);
      // scene.add( circle );
  
      // const visible = visibleBox(70);
    //   const a = Math.max(size.x, size.y, size.z);
    //   const s = Math.sign(props.position[1]);
    //   const y = Math.abs(visible.min.y) - Math.abs(a) 
    //   const ny = s * y;
    //   console.log(">> ]]] " + mesh.current.position.x + " --> " +  y  + " " + props.position + " " + props.position[1]);
    //   mesh.current.position.set(props.position[0], ny, props.position[2]);
    // }
      
    // })

    // console.log("props " + props);
    // const childrenWithLayers = React.Children.map(children, child => {
    //     if (React.isValidElement(child) && layers !== undefined) {
    //         return React.cloneElement(child, {layers: layers});
    //     }
    //     return child;
    // });

    return (
      
      <mesh
        // {...props}
        ref={mesh}
        scale={active ? [30, 30, 30] : [1, 1, 1]}
        onClick={handleOnClick}
        onPointerOver={(e) => setHover(true) }
        onPointerOut={(e) => setHover(false)}>
            {hovered && mesh.current.visible ? <Html ref={html} scaleFactor={50}>
                    <div className="content">
                        {text}
                </div>
            </Html> :null}
        {/* {childrenWithLayers} */}
        {children}
      </mesh>
    )
  }