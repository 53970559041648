import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useFrame } from '@react-three/fiber'
import { Html } from '@react-three/drei'
import { useNavigate } from 'react-router-dom';
import {
  useHistory,
  useRouteMatch
} from "react-router-dom";

export default function Background(props) {
    // let navigate = useNavigate();
    const mesh = useRef()

    const {rotateX = 0.00008} = props;
    const {rotateY = 0.00006} = props;
    const {link} = props;
      
    // useFrame(() => {
    //   if (mesh.current == null) {
    //     return;
    //   }
    //   mesh.current.rotation.x += rotateX;
    //   mesh.current.rotation.y += rotateY;
    // })
  
    // const handleOnClick = useCallback(() => {
    //   if (props.link !== undefined) {
    //     navigate(link);
    //   } else {
    //     navigate("/")
    //   }
    // }, [link]);

    return (
      
      <mesh
        {...props}
        ref={mesh}
        // onClick={handleOnClick}
        >
        {props.children}
      </mesh>
    )
  }