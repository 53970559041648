import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'

import { EffectComposer, DepthOfField, Bloom, Noise, Vignette, Glitch } from '@react-three/postprocessing'
import { GlitchMode } from 'postprocessing'

// import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
// import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass'
// import { FilmPass } from 'three/examples/jsm/postprocessing/FilmPass'
// import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
// import { SSAOPass } from 'three/examples/jsm/postprocessing/SSAOPass'
// import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
// import { DotScreenPass } from 'three/examples/jsm/postprocessing/DotScreenPass'


import Model from './Knight_hand';
import Candy from './Cotton_candy';
import Hand from './Hand';
import Tape from './Tape';
import Fist from './Fist';
import Lovers from './Lovers';
import DisposableCamera from './Disposable_camera';
import Cable from './Cable';
import Thing from './Thing';
// import Effects from './Effects';
import Background from './Background';
import * as THREE from 'three'
import { useThree, extend, Canvas, useFrame, Renderer } from '@react-three/fiber'
// import { Html } from '@react-three/drei'

// import history from "history";
import { useLanguage } from './contentful';
import { useNavigate, useLocation } from 'react-router-dom';


// extend({ EffectComposer, GlitchPass, ShaderPass, RenderPass, UnrealBloomPass, SSAOPass, FilmPass, DotScreenPass, ShaderPass })


export const AppContext = React.createContext(null);

export default function Homepage({location, navigate}) {
const [language, setLanguage] = useLanguage();
// let navigate = useNavigate();
  // let location = props.;
// let page = useLocation().pathname;
  // let page = useLocation().pathname;

  // const homepage = useRef();

  const camera = useThree((state) => state.camera);

  var [visibleProperties, setVisibleProperties] = useState({
    size: new THREE.Box2(new THREE.Vector2(-11, 11), new THREE.Vector2(11, -11))
  });

  useLayoutEffect(() => {
    // camera.layers.enable(0)
    // camera.layers.enable(1)
    // camera.layers.enable(2)
  }, [])

  useEffect(() => {
    // const unlistenLocation = window.appHistory.listen(({ action, location }) => {
    //   if (window.appHistory.location.pathname === "/") {
    //     camera.fov = 30;
    //   } else {
    //     camera.fov = 10;
    //   }
    //   camera.updateProjectionMatrix();
    // });

    function handleResize() {
      var t = Math.tan(THREE.Math.degToRad(camera.fov) / 2)
      var h = t * camera.position.z;
      var w = h * camera.aspect;
      setVisibleProperties({ size: new THREE.Box2(new THREE.Vector2(-w, h), new THREE.Vector2(w, -h)) });
    }
    window.addEventListener('resize', handleResize);
    // return unlistenLocation;
  }, [])


  // function ThreeRoute({path, children}) {
  //   if ((path === '/' && location.pathname === '/')
  //     || (path.length > 1 && location.pathname.startsWith(path))) {
  //     return <>{children}</>;
  //   } else {
  //     return <></>;
  //   }
  // }

  const switchLanguage = () => {
    if (language === "cs") {
      setLanguage("en");
    } else if (language === "en") {
      setLanguage("cs");
    }
  }

  return (
    <>
      {/* <Suspense fallback="NULL"> */}
        {/* <AppContext.Provider value={visibleProperties}> */}
        {/*}  <ThreeRoute path="/"> */}
            <Thing position={[-4, 0, 0]} text="bio" link="bio" navigate={navigate}>
              <Model position={[0, 0, 0]} scale={[0.7, 0.7, 0.7]} />
            </Thing>
            <Thing position={[-12, 12, 0]} text="portfolio" link="portfolio" navigate={navigate}>
              <Candy position={[0, 0, 0]} scale={[0.6, 0.6, 0.6]} />
            </Thing>

            <Thing position={[0, 10, 0]} text={language == "cs" ? "novinky" : "news"} link="news" navigate={navigate}>
              <DisposableCamera position={[0, 0, 0]} scale={[0.4, 0.4, 0.4]} />
            </Thing>
            <Thing position={[5, -7, 0]} navigate={navigate}>
              <Cable position={[0, 0, 0]} scale={[1.6, 1.6, 1.6]} />
            </Thing>
            <Thing position={[14.5, -11, 0]} text={language == "cs" ? "english" : "čeština"} layers={1} navigate={navigate} action={switchLanguage}>
              <Tape position={[0, 0, 0]} scale={[0.56, 0.56, 0.56]} />
            </Thing>
            <Thing position={[-13, -12, 0]} text={language == "cs" ? "kontakt" : "contacts"} link="contact" navigate={navigate}>
              <Fist position={[0, 0, 0]} scale={[0.7, 0.7, 0.7]} />
            </Thing>
            <Lovers position={[0, 0, 0]} scale={[camera.aspect, camera.aspect, camera.aspect]} layers={1} navigate={navigate}/>
            
              <Hand position={[15, 7, 0]} scale={[1.3, 1.3, 1.3]} />
            
          {/* </ThreeRoute>

          <ThreeRoute path="/portfolio">
            <Background rotateX={0.0005} rotateY={0.0005}>
              <Candy scale={[9, 9, 9]} />
            </Background>
            {/* <Thing position={[-12, 12, 0.1]} text="portfolio" link="portfolio">
              <Candy position={[0, 0, 0]} scale={[0.2, 0.2, 0.2]} />
            </Thing> 
          </ThreeRoute>

          <ThreeRoute path="/exhibition">
            <Background rotateX={0.0005} rotateY={0.0005}>
              <Candy scale={[9, 9, 9]} />
            </Background>
          </ThreeRoute>

          <ThreeRoute path="/bio">
            <Background>
              <Model scale={[7, 7, 7]} />
            </Background>
          </ThreeRoute>

          <ThreeRoute path="/newspage">
            <Background>
              <DisposableCamera scale={[4, 4, 4]} />
            </Background>
          </ThreeRoute>

          <ThreeRoute path="/news">
            <Background>
              <DisposableCamera scale={[4, 4, 4]} />
            </Background>
          </ThreeRoute>

          <ThreeRoute path="/contact">
            <Background rotateX={0.0005} rotateY={0.005}>
              <Fist scale={[7, 7, 7]} />
            </Background>
          </ThreeRoute>
 */}
        {/* </AppContext.Provider> */}
      {/* </Suspense> */}
      
    </>
  );
}

function Effects(props) {
  // let location = useLocation();
  const { gl, scene, camera, size } = useThree()
  const composer = useRef()

  useEffect(() => {
    composer.current.setSize(size.width, size.height);
  }, [size])

  // useFrame((state, delta) => {
  //   if (composer.current === null) {
  //     return;
  //   }
  //   gl.autoClear = false;
  //   gl.clear();
  //   camera.layers.set(0)
  //   gl.render(scene, camera);

  //   camera.layers.set(1)
  //   gl.render(scene, camera);

  // //   if (location.pathname === "/") {
  //     // camera.layers.set(2)
  //     // composer.current.swapBuffers();
  //     // composer.current.render()
  // //   }
  // }, 1)

  return (
    <EffectComposer ref={composer} args={[gl]}>
      <Bloom/>
      {/* <RenderPass attachArray="passes" scene={scene} camera={camera} /> */}
      {/* <FilmPass attachArray="passes" args={[0.8, 0.4, 256, false]} material-transparent={true} /> */}
      <Glitch mode={GlitchMode.SPORADIC}/>
    </EffectComposer>
  )
}