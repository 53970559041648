import React, { useState } from 'react'
import './App.css';
import Homepage from './Homepage'
import Portfolio from './Portfolio'
import Exhibition from './Exhibition'
import { Jb, Prague, Topcorner, Infinity } from './Jb';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import TextEntry from './TextEntry';
import { useLanguage } from './contentful';
import { useProgress } from '@react-three/drei'
import ReactGA from 'react-ga';
import { useNavigate, useLocation } from 'react-router-dom';
import Model from './Knight_hand';
import Candy from './Cotton_candy';
import Fist from './Fist';
import DisposableCamera from './Disposable_camera';
import Background from './Background';
import Hand from './Hand';
import { useThree, extend, Canvas, useFrame, Renderer } from '@react-three/fiber'
import Effects from './Effects';

import * as THREE from 'three'

// const customHistory = createBrowserHistory({
// basename: config.urlBasename || ""
// });


ReactGA.initialize('UA-90023537-1', {
  gaOptions: { name: 'tracker2' }
});

// customHistory.listen((location) => {
//   ReactGA.pageview(location.pathname + location.search);
// });

function App() {
  // let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useLanguage();

  const { loaded } = useProgress()
  

  return (
    <div className="App-main">
      <div className="App-content">
        <BrowserRouter>
          <Routes>
            <Route path="/portfolio" element={
              <>
                <Jb />
                <Portfolio tags="exhibition" detailpath="exhibition" />
                <SingleCanvas>
                  <Background rotateX={0.0005} rotateY={0.0005}>
                    <Candy scale={[9, 9, 9]} />
                  </Background>
                </SingleCanvas>
              </>
            } />

            <Route path="/bio" element={
              <>
                <Jb />
                
                <div className="bio">
                  <TextEntry entryId="6iWjc7jHWpkfPIni6ki65u" />
                </div>
                <SingleCanvas>
                  <Background>
                    <Model scale={[7, 7, 7]} />
                  </Background>
                </SingleCanvas>
              </>
            } />

            <Route path="/news" element={
              <>
                <Jb />
                <Portfolio tags="news" detailpath="newspage" />
                <SingleCanvas>
                  <Background>
                    <DisposableCamera scale={[4, 4, 4]} />
                  </Background>
                </SingleCanvas>
              </>
            } />

            <Route path="/contact" element={
              <>
                <Jb />
                <div className="bio">
                  <TextEntry entryId="4bujQQDllXu3vLt0rLunjK" />
                </div>
                <SingleCanvas>
                  <Background rotateX={0.0005} rotateY={0.0005}>
                    <Fist scale={[7, 7, 7]} />
                  </Background>
                </SingleCanvas>
              </>
            } />

            <Route path="/exhibition">
              <Route path=":id" element={
                <>
                  <Topcorner short="p" long="portfolio" path="/portfolio" />
                  <Exhibition />
                  <SingleCanvas>
                    <Background rotateX={0.0005} rotateY={0.0005}>
                      <Candy scale={[9, 9, 9]} />
                    </Background>
                  </SingleCanvas>
                </>
              } />
            </Route>

            <Route path="/newspage">

              <Route path=":id" element={
                <>
                  <Topcorner short="n" long={language === "cs" ? "novinky" : "news"} path="/news" />
                  <div>
                    <Exhibition />
                  </div>
                  <SingleCanvas>
                    <Background>
                      <DisposableCamera scale={[4, 4, 4]} />
                    </Background>
                  </SingleCanvas>
                </>
              } />
            </Route>

            <Route path="/hand" element={
              <>
                <Jb />
                
                <SingleCanvas>
                  <Background rotateX={0.0005} rotateY={0.0005}>
                    <Hand scale={[1.2, 1.2, 1.2]} />
                  </Background>
                </SingleCanvas>
              </>
            } />

            <Route path="/" element={
              <>
                <Jb />
                <Prague />
                <HomepageCanvas />
              </>
            } />

          </Routes>
        </BrowserRouter>
        <Infinity fade={!loaded ? 'fadeIn' : 'fadeOut'} />
      </div>


    </div>
  );
}



function SingleCanvas({ children }) {
  let navigate = useNavigate();
  let location = useLocation();


  return <Canvas
    concurrent="true"
    gl={{ alpha: true, antialias: true }}
    camera={{ position: [0, 0, 70], fov: 10, near: 3, far: 800 }}
    onCreated={({ gl, camera }) => {
      camera.lookAt(0, 0, 0)
      console.log(camera.aspect);
      // setLoading(false);
    }}
  // onPointerMissed={() => window.appHistory.push("/")}
  // onPointerMissed={() => navigate("/")}
  >

    <ambientLight intensity={0.1} color={0xbbffbb} layers={[2]} />
    <ambientLight intensity={0.1} color={0xbbffbb} layers={[0]} />

    <pointLight position={[-10, -10, 60]} color={0xccccff} layers={[2]} />
    <pointLight position={[-10, -10, 60]} color={0xccccff} layers={[0]} />
    {children}
  </Canvas>
}

function HomepageCanvas() {
  let navigate = useNavigate();
  let location = useLocation();

  
  return <Canvas
    concurrent="true"
    gl={{ alpha: true, antialias: true }}
    camera={{ position: [0, 0, 70], fov: 30, near: 3, far: 800 }}
    onCreated={({ gl, camera }) => {
      camera.lookAt(0, 0, 0)
      console.log(camera.aspect);
      // setLoading(false);
    }}
    // onPointerMissed={() => window.appHistory.push("/")}
    onPointerMissed={() => navigate("/")}
  >

    <ambientLight intensity={0.1} color={0xbbffbb} layers={[2]} />
    <ambientLight intensity={0.1} color={0xbbffbb} layers={[0]} />

    <pointLight position={[-10, -10, 60]} color={0xccccff} layers={[2]} />
    <pointLight position={[-10, -10, 60]} color={0xccccff} layers={[0]} />
    <Homepage location={location} navigate={navigate} />
    {/* <Effects/> */}
  </Canvas>
}

export default App;
