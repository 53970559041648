import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'

export default function Model(props) {
  const group = useRef()
  const mesh = useRef()
  const { nodes, materials } = useGLTF('/lovers.glb')

  useFrame(() => {
    if (group.current === null) return;
    group.current.rotation.y += 0.002;

  })

  return (
    <group ref={group} {...props}>
      <mesh ref={mesh} geometry={nodes.Curve.geometry} scale={[2.5, 2.5, 2.5]} rotation={[Math.PI / 2, 0, 0]} layers={0}>
        <meshStandardMaterial color={'white'} emissive={'white'} emissiveIntensity={100}/>
      </mesh>
    </group>
  )
}

useGLTF.preload('/lovers.glb')
