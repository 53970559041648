import React, { useEffect, useRef, useState, useCallback } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

const contentful = require("contentful");

const space_id = "i8m2y70mrr1u";

const useLanguage = () => {

    const [language, setLanguage] = useState(window.localStorage.getItem("lang"));

    useEffect(() => {
        try {
            const storedLang = window.localStorage.getItem("lang");
            if (storedLang !== undefined && storedLang !== null) {
                setLanguage(storedLang);
                return;
            }
            var lang
            if (navigator.languages != undefined) {
                lang = navigator.languages[0];
            } else {
                lang = navigator.language;
            }
            if (lang.startsWith("en-")) {
                setLanguage("en");
            }
        } catch (error) {
            // If error also return initialValue
            console.log("Error reading lang " + error);
        }
        setLanguage("cs");
    });

    const setStoredLanguage = lang => {
        try {
            setLanguage(lang);
            window.localStorage.setItem("lang", lang);
        } catch (error) {
            console.log(error);
        }
    };

    return [language, setStoredLanguage];
}

export const QueryBy = {
    ENTITY: 0,
    PATH: 1
}

const useFetchEntry = (queryBy, path) => {
    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState("");
    const [error, setError] = useState("");
    const [language, setLanguage] = useLanguage();

    const space_id = "i8m2y70mrr1u";

    useEffect(() => {
        const client = contentful.createClient({
            space: space_id,
            accessToken: "GXxTSCt0I7ZA-NHelDmYhU9wtgkc9EOomTEpS985cKk"
        });

        if (queryBy == QueryBy.ENTITY) {
            client.getEntry(path, { locale: language })
                .then(function (entry) {
                    setResult(entry)
                    setLoading(false);
                }).catch((error) => {
                    setError(error);
                })
        } else if (queryBy == QueryBy.PATH) {
            client.getEntries({ 'fields.path': path, 'content_type': 'exhibition', locale: language })
                .then(function (entries) {
                    if (entries?.items.length > 0) {
                        setResult(entries.items[0]);
                    } else {
                        setError("404");
                    }
                    setLoading(false);
                }).catch((error) => {
                    setError(error);
                })
        }
        
    }, [language]);

    return {
        error,
        loading,
        result
    };
}

const renderText = (text) => {
    if (text === undefined) {
        return "";
    }
    console.log(text)
    const Bold = ({ children }) => <p className="bold">{children}</p>;
    const Text = ({ children }) => <p className="bio-text">{children}</p>;

    const options = {
        renderMark: {
            [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
        },
        // renderText: text => text.replace('!', '?'),
        renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text])
    };

    return documentToReactComponents(text, options)
}

const useImageUrl = ({ origUrl }) => {
    let parts = origUrl.split("/");
    let id = parts[4];
    let token = parts[5];
    let fileName = parts[6];
    return `https://images.ctfassets.net/${space_id}/${id}/${token}/${fileName}?w=${1024}&h=${1024}`
}

export { useFetchEntry, useImageUrl, useLanguage };