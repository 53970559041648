import { EffectComposer, Glitch, DepthOfField, Bloom, Noise, Vignette } from '@react-three/postprocessing'

import { GlitchMode } from 'postprocessing'

// import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
// import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass'
import { FilmPass } from 'three/examples/jsm/postprocessing/FilmPass'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { SSAOPass } from 'three/examples/jsm/postprocessing/SSAOPass'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
import { DotScreenPass } from 'three/examples/jsm/postprocessing/DotScreenPass'

import { useThree, extend, Canvas, useFrame, Renderer } from '@react-three/fiber'
import React, { Suspense, useEffect, useState, useRef, useLayoutEffect, useMemo } from 'react'


extend({ ShaderPass, RenderPass, UnrealBloomPass, SSAOPass, FilmPass, DotScreenPass, ShaderPass })

export default function Effects(props) {
    // let location = useLocation();
    const { gl, scene, camera, size } = useThree()
    const composer = useRef()

    // useEffect(() => {
    //     composer.current.setSize(size.width, size.height);
    // }, [size])

    // useFrame((state, delta) => {
    //     if (composer.current === null) {
    //         return;
    //     }
    //     gl.autoClear = false;
    //     gl.clear();
    //     camera.layers.set(0)
    //     gl.render(scene, camera);

    //     camera.layers.set(1)
    //     gl.render(scene, camera);
    //     //   composer.current.render();
    //     //   if (location.pathname === "/") {
    //     camera.layers.set(2)
    //     // gl.render(scene, camera);
    //     // composer.current.swapBuffers();
    //     composer.current.render()
    //     //   }
    // }, 1)

    return (
        <EffectComposer ref={composer} args={[gl]}>
            {/* <renderPass attachArray="passes" scene={scene} camera={camera} />
            <filmPass attachArray="passes" args={[0.8, 0.4, 256, false]} material-transparent={true} /> */}
            {/* <glitchPass attachArray="passes" args={[100]} material-transparent={true} /> */}

            <Glitch
                delay={[1.5, 6.5]} // min and max glitch delay
                duration={[0.1, 0.3]} // min and max glitch duration
                strength={[0.04, 0.1]} // min and max glitch strength
                mode={GlitchMode.SPORADIC} // glitch mode
                active // turn on/off the effect (switches between "mode" prop and GlitchMode.DISABLED)
                ratio={0.65} // Threshold for strong glitches, 0 - no weak glitches, 1 - no strong glitches.
            />

        </EffectComposer>
    )
}