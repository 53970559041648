import React, { useEffect, useRef, useState, useCallback } from 'react'
import { Html } from '@react-three/drei'
import './Portfolio.css'
import {isMobileOnly} from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

const contentful = require("contentful");
var drag = null;

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default function Portfolio({tags, detailpath}) {
    let navigate = useNavigate();
    const space_id = "i8m2y70mrr1u";
    const token = "";
    const scrollRef = useRef();
    
    const [images, setImages] = useState([]);

    const [scrollTop, setScrollTop] = useState(0);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const prevWidth = usePrevious(windowWidth)

    const [entries, setEntries] = useState([]);
    
    

    const client = contentful.createClient({
        // This is the space ID. A space is like a project folder in Contentful terms
        space: space_id,
        // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
        accessToken: "GXxTSCt0I7ZA-NHelDmYhU9wtgkc9EOomTEpS985cKk"
    });

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    }

    function vw(v) {
        return Math.floor((v * windowWidth) / 100);
      }

    useEffect(() => {
        console.log("LISTEN")
        window.addEventListener('resize', handleResize);
        return () => {
            console.log("UNLISTEN")
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    useEffect(() => {
        client.getEntries({
            // 'select': 'fields.title',
            'content_type': 'exhibition',
            'fields.tags': tags,
            'order': '-fields.date'
        }).then(function (entries) {
            setEntries(entries);
        }).catch(console.error)
        document.addEventListener('pointerup', endMove)
    }, []);

    useEffect(() => {
        setImages([]);
        if (entries.items === undefined) {
            return;
        }
        const tmpImages = new Map();
        entries.items.forEach(function (entry, i) {
            // exhibition.push({ 
            //     title: entry.fields.title, 
            //     image: entry.fields.images[0].sys.id,
            //     exhibitionId: entry.sys.id })
            const img_id = entry.fields.images[0].sys.id;
            const path = entry.fields.path;
            tmpImages.set(img_id, { 
                title: entry.fields.title, 
                // image: entry.fields.images[0].sys.id,
                exhibitionId: entry.sys.id,
                path,
                index: i });
        })

        const columnsCount = isMobileOnly ? 1 : 2
        const maxTop = isMobileOnly ? 60 : 120;
        const maxRnd = isMobileOnly ? 24 : 16;
        
        entries.includes.Asset.forEach((asset) => {
            
            const value = tmpImages.get(asset.sys.id);
            if (value !== undefined) {
                
                let newWidth, newHeight;
                const width = asset.fields.file.details.image.width;
                const height = asset.fields.file.details.image.height
                if (width > height) {
                    newWidth = 50;
                    newHeight = Math.floor(50 * height / width);
                } else {
                    newHeight = 50;
                    newWidth = Math.floor(50 * width / height);
                }
                let parts = asset.fields.file.url.split("/");
                let token = parts[5];
                let fileName = parts[6];

                const row = Math.floor(value.index / columnsCount);
                const column = (value.index % columnsCount);
                
                let top = Math.trunc(row * (windowWidth / 2.3) + getRandomInt(width/maxRnd/2));
                if (value.index == 0) {
                    top = maxTop + getRandomInt(30);
                } else if (top <= maxTop) {
                    top = maxTop + 30 + getRandomInt(60)
                }
                let left = isMobileOnly ? Math.trunc(windowWidth / 2 - vw(newWidth) /2 + getRandomInt(windowWidth/3) - windowWidth/(6))  
                                : Math.trunc(column * (windowWidth / 2.5) + getRandomInt(width/maxRnd));   
                if ((left + vw(newWidth)) > windowWidth) {
                    left = windowWidth - vw(newWidth) - 90;
                }             
                console.log("index " + value.index + " top: " + top + " left: " + left + " windowWidth: " + windowWidth + " oIndex: " + value.index);
                 
                const layer = 100 - value.index;
                
                setImages(oldArray => [...oldArray, 
                    Object.assign(value, { 
                        url: `https://images.ctfassets.net/${space_id}/${asset.sys.id}/${token}/${fileName}?w=1600&h=1600&fm=webp&q=90`,
                        id: asset.sys.id,
                        width: newWidth + "vw",
                        height: newHeight + "vw",
                        top: top,
                        left: left,
                        layer: layer,
                        row: row,
                        column: column,
                        createdAt: asset.sys.createdAt
                    })
                ]);
            }
        })
        
    }, [entries]);

    useEffect(() => {
        let items = [...images];
        items.forEach(img => {
            img.top = Math.round(img.top * windowWidth / prevWidth);
            img.left = Math.round(img.left * windowWidth / prevWidth);
        })
        setScrollTop(scrollRef.current.scrollTop);
        setImages(items);

    }, [windowWidth]);

    const setPosition = (i, top, left, scrollTop) => {
        let items = [...images];
        let img = {...items[i]};
        img.top = top;
        img.left = left;
        items[i] = img;
        const layer = items[i].layer;
        items[i].layer = 100;
        items.forEach(img => {
            if (img.layer > 0) img.layer = img.layer -1;
        })
        setScrollTop(scrollRef.current.scrollTop);
        setImages(items);
    };

    useEffect(() => {
        scrollRef.current.scrollTop = scrollTop;
    }, [images]);

    const mouseMove = useCallback((e) => {
        if (drag != null) {
            e.preventDefault();       
            drag(e.clientX, e.clientY);
        }
    }, []);

    const endMove = e => {
        document.removeEventListener('pointermove', mouseMove, true);
        drag = null;
        scrollRef.current.style.overflowY = 'scroll';
    }

    const startMove = (callback) => {
        
        drag = callback;
        document.addEventListener('pointermove', mouseMove, true)
        scrollRef.current.style.overflowY = 'hidden';
    }

    function getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    const Exhibition = (props) => {
        const [timestamp, setTimestamp] = useState(Date.now());

        const noContext = (event) => {
            event.preventDefault();
        }
        const ref = useRef();
        var offsetX, offsetY;

        var top = props.img.top;
        var left = props.img.left;

        const dragMouseDown = (e) => {
            e.preventDefault();
            e.stopPropagation(); 
            ref.current.style.cursor = 'move';
            ref.current.style.zIndex = '101';
            var rect = ref.current.getBoundingClientRect();
            offsetX = e.clientX - rect.left;
            offsetY = e.clientY - rect.top - scrollRef.current.scrollTop;

            // TODO why this doesn't work properly??
            // setOffset({x: (e.clientX - rect.left), y: (e.clientY - rect.top - scrollRef.current.scrollTop)});
            setTimestamp(Date.now());
            props.startMove(elementDrag);
        };

        var timer;
        const mouseUp = (e) => {
            if ((Date.now() - timestamp) < 200) {
                clearTimeout(timer)
                navigate("/" + detailpath +"/" + props.img.path);
            }
            setPosition(
                props.index, 
                parseInt(ref.current.style.top, 10), 
                parseInt(ref.current.style.left, 10));
            ref.current.style.cursor = 'pointer'
        } 

        const elementDrag = (x, y) => {
            top = y - offsetY;
            left = x - offsetX;
            ref.current.style.top = top + 'px';
            ref.current.style.left = left + 'px';
        }


        useEffect(() => {
            ref.current.style.top = top + 'px';
            ref.current.style.left = left + 'px';
        }, [])
        
        return <div ref={ref}
            onPointerDown={dragMouseDown}
            onMouseLeave={(e) => ref.current.style.cursor = 'pointer'}
            onMouseEnter={(e) => {ref.current.style.cursor = 'move';
                                timer = setTimeout(() => ref.current.style.cursor = 'pointer', 500);
            }}
            onPointerUp={mouseUp}
            style={{zIndex: props.img.layer, top: top, left: left}}>
            <img src={props.img.url} 
                draggable="false" 
                onPointerDown={noContext} 
                onDragStart={() => { return false; }} 
                style={{width: props.img.width, height: props.img.height}}/>
        </div>
    }

    // console.log(JSON.stringify(images))
    return <div className='flex-container' ref={scrollRef}>
        <div className='portfolio-content'>
            {images.map((img, i) => {
                return <Exhibition key={img.exhibitionId} startMove={startMove} img={img} index={i} setPosition={(i, top, left) => setPosition(i, top, left)}/>
            })}
        </div>
    </div>
}