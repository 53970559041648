import React, { useEffect, useRef, useState, useCallback } from 'react'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

import { useFetchEntry, QueryBy } from './contentful';

const TextEntry = ({entryId}) => {
    const { loading, result, error } = useFetchEntry(QueryBy.ENTITY, entryId);
    const [text, setText] = useState("LOADING...");

    const Bold = ({ children }) => <p className="bold">{children}</p>;

    const Text = ({ children }) => <p className="bio-text">{children}</p>;

    const options = {
        renderMark: {
            [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
        },
        // renderText: text => text.replace('!', '?'),
        renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text])
    };

    useEffect(() => {
        if (!loading) {
            setText(documentToReactComponents(result.fields.text, options));
        }
    }, [loading]);

    return <span>
        {text}
    </span>
}
export default TextEntry;