import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";
import ReactMarkdown from 'react-markdown'
import breaks from 'remark-breaks'

import { useFetchEntry, QueryBy } from './contentful';

import './Exhibition.css'

const Exhibition = () => {
    let { id } = useParams();
    const { loading, result, error } = useFetchEntry(QueryBy.PATH, id);

    // console.log("* loading " + loading);
    // console.log("* error " + error);
    if (loading || error) {
        return loading ? "LOADING..." : error;
    } 
    // document.title = "Jana Bernartová " + result.fields.title;

    // const images = [];
    const images = result.fields.images.map(image => {
        // useImageUrl(image.fields.file.url);
        // let newWidth, newHeight;
        // const width = image.fields.file.details.image.width;
        // const height = image.fields.file.details.image.height
        // if (width > height) {
        //     newWidth = "66vw";
        //     newHeight = 66 * height / width + "vw";
        // } else {
        //     newHeight = "66vw";
        //     newWidth = 66 * width / height + "vw";
        // }

        let parts = image.fields.file.url.split("/");
        let id = parts[4];  
        let token = parts[5];
        let fileName = parts[6];
        let space_id = parts[3];
        return <img key={id} src={`https://images.ctfassets.net/${space_id}/${id}/${token}/${fileName}?w=1600&h=1600&q=90`} />

    });

    return <div className="bio">
        <div className="exhibitions">
            {images}
        </div>
        <div className="exhibitions-note">
            <ReactMarkdown children={result.fields.title} plugins={[breaks]} />
            <ReactMarkdown children={result.fields.note} plugins={[breaks]} />
            <ReactMarkdown children={result.fields.text} plugins={[breaks]} />
        </div>
    </div>

}

export default Exhibition;