import './Jb.css';
import React, { useEffect, useState } from 'react'
import { useLanguage } from './contentful';
import { useNavigate } from 'react-router-dom';

export function Jb() {
  let navigate = useNavigate();
  const [hovered, setHover] = useState(false)

  return (
    <div className="topcorner"
        onPointerOver={(e) => setHover(true)}
        onPointerOut={(e) => setHover(false)}
        onClick={(e) => navigate("/")}>
      {hovered ? 'jana\u00A0bernartová' : "jb"}
    </div>
  )
}

export function Topcorner({long, short, path}) {
  let navigate = useNavigate();
  const [hovered, setHover] = useState(false)

  return (
    <div className="topcorner"
        onPointerOver={(e) => setHover(true)}
        onPointerOut={(e) => setHover(false)}
        onClick={(e) => navigate(path)}>
      {hovered ? long : short}
    </div>
  )
}

export function Infinity({fade}) {
  const style = "central " + fade;
    return (
      <div className={style} >
        ∞
      </div>
      
    )
  }

  export function Prague(props) {

    const CZECH = "Projekt podpořilo Hlavní město Praha!";
    const ENGLISH = "The project was supported by the City of Prague!";
    const [hovered, setHover] = useState(false)
    const [text, setText] = useState(CZECH)
    const [language, setLanguage] = useLanguage();

    
    useEffect(() => {
      if (language == "cs") {
        setText(CZECH);
      } else {
        setText(<span style={{fontSize: "calc(0.7em + 0.5vw)"}}>{ENGLISH}</span>);
      }
    }, [language]);

    return (
      <div className="bottomcorner" 
          onPointerOver={(e) => setHover(true)}
          onPointerOut={(e) => setHover(false)}
          >
        {hovered ? text : <span style={{fontWeight: '700'}}>!</span>}
      </div>
    )
  }